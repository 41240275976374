import HTTP from './HttpService'

var SystemProperty = {

  createSystemProperty: async function (systemProperty) {
    var responseSystemProperty = {};

    await HTTP.http().post('/systemproperty/create', systemProperty)
      .then(function(result) {
        responseSystemProperty = result.data;
      })
      .catch(function(error) {
        console.log('Create system property error: ' + error);
      });

    return responseSystemProperty;
  },

  deleteSystemProperty: async function (systemPropertyId) {
    var deleteResponse = {};

    await HTTP.http().delete('/systemproperty/delete/' + systemPropertyId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete system property error: ' + error);
      });

    return deleteResponse;
  },

  getAllSystemProperties: async function() {
    var systemProperties = [];

    await HTTP.http().get('/systemproperty/fulllist')
      .then(function(response) {
        if (response) {
          systemProperties = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load all system propertys error: ' + error);
      });

      return systemProperties;
  },

  updateSystemProperty: async function (systemProperty) {
    var responseSystemProperty = {};

    await HTTP.http().post('/systemproperty/update', systemProperty)
      .then(function(result) {
        responseSystemProperty = result.data;
      })
      .catch(function(error) {
        console.log('Update system property error: ' + error);
      });

      return responseSystemProperty;
  }

}

export default SystemProperty;
