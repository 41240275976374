<template>

  <div class="container">

    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h5>System Property Administration</h5>
          </div>
          <div class="col-4">
            <input id="filter" placeholder="Filter by Environment, Value or Description" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div v-if="isAdmin" class="col-4" style="text-align:right;">
            <div class="col-auto custom-cursor-pointer mt-2" @click="addSystemProperty()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add System Property</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th scope="col">Environment</th>
            <th scope="col">Sub Environment</th>
            <th scope="col">Value</th>
            <th scope="col">Description</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(systemProperty, index) in filteredResult" :key="systemProperty.id">
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'environment', index: index, value: systemProperty.environment}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{systemProperty.environment}}</label>
            </td>
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'subEnvironment', index: index, value: systemProperty.subEnvironment}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{systemProperty.subEnvironment}}</label>
            </td>
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'value', index: index, value: systemProperty.value}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{systemProperty.value}}</label>
            </td>
            <td>
              <TextInputField v-if="isAdmin" :passedData="{name: 'description', index: index, value: systemProperty.description}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{systemProperty.description}}</label>
            </td>
            <td class="text-right custom-cursor-pointer">
              <i v-if="isAdmin" class="fa fa-trash" aria-hidden="true" @click="deleteSystemProperty(systemProperty)" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'
import Store from '@/store'
import SystemProperty from '@/services/SystemPropertyService'
import TextInputField from '@/components/util/TextInputField'

export default {
  name: 'systemProperties',
  components: {
    TextInputField
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.systemProperties.filter(result => {
        return (
          result.environment.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.subEnvironment.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.value.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.description.toLowerCase().match(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  created: async function () {
    this.systemProperties = await SystemProperty.getAllSystemProperties();
  },
  data: function () {
    return {
      systemProperties: [],
      searchTerm: "",
    }
  },
  methods: {
    addSystemProperty: async function() {
      var systemProperty = {
        environment: "Change Me",
        subEnvironment: "Change Me",
        value: "Change Me",
        description: "Change Me"
      }

      systemProperty = await SystemProperty.createSystemProperty(systemProperty);
      this.systemProperties.push(systemProperty);
    },
    changeFromComponent: async function(returnData) {
      var name = returnData.name;
      var idx = returnData.index;
      var systemProperty = this.systemProperties[idx];
      var newValue = returnData.newValue;

      systemProperty[name] = newValue;
      this.systemProperties[idx] = await SystemProperty.updateSystemProperty(systemProperty);
    },
    deleteSystemProperty: async function(systemProperty) {
      this.systemProperties = lodash.without(this.systemProperties, systemProperty);
      await SystemProperty.deleteSystemProperty(systemProperty.id);
    }
  }
}
</script>
